

























































































import { mapActions, mapState } from 'vuex';
import CodeHighlightedBlock from '@/components/CodeHighlightedBlock.vue';
import isEqual from 'lodash/isEqual';
import * as vr from '@/helpers/validation';
import {
  integer,
  required,
  validNumber,
  positiveNumber
} from '@/helpers/validation';

import {
  updateCopystakeIntegration,
  updateCopystakeIntegrationMode
} from '@/api/CopyStake/Integration';
import { AxiosError } from 'axios';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import {
  CopyStakeCopyModeNaming,
  ECopyStakeCopyMode,
  ECopyStakeIntegrationMode
} from '@/api/schema';
import { generateNocodeIntegrationCode } from '@/helpers/copyStakeHelpers';

type TSettingsValues = {
  copyMode: ECopyStakeCopyMode;
  apiKey: string;
  ttl: string | number;
  callbackUrl: string;
  brandName: string;
};

type TSettingsData = {
  ECopyStakeIntegrationMode?: typeof ECopyStakeIntegrationMode;
  copyModesList: ReadonlyArray<{
    text: string;
    value: ECopyStakeCopyMode;
  }>;
  values: TSettingsValues;
  formValid: boolean;
  isSubmitting: boolean;
  isSubmittingIntegrationMode: boolean;
};

type TFormField = {
  label: string;
  tooltip: string;
  key: string;
  classes: Record<string, boolean>;
};

type TValidator = (v: string) => boolean | string;

export default {
  name: 'OnboardingCopystakeSettings',
  components: {
    CodeHighlightedBlock
  },
  data(): TSettingsData {
    return {
      ECopyStakeIntegrationMode,
      copyModesList: Object.freeze([
        {
          text: CopyStakeCopyModeNaming.ON_FLY,
          value: ECopyStakeCopyMode.ON_FLY
        },
        {
          text: CopyStakeCopyModeNaming.IN_POOL,
          value: ECopyStakeCopyMode.IN_POOL
        }
      ]),

      values: {
        copyMode: ECopyStakeCopyMode.ON_FLY,
        apiKey: null,
        ttl: null,
        callbackUrl: null,
        brandName: 'Copystake'
      },

      formValid: false,
      isSubmitting: false,
      isSubmittingIntegrationMode: false
    };
  },
  computed: {
    ...mapState('app', ['isSuperAdmin']),
    ...mapState('Onboarding', ['copyStakeIntegration', 'loading']),

    savedValues(): TSettingsValues {
      return {
        copyMode:
          this.copyStakeIntegration.copyMode || ECopyStakeCopyMode.ON_FLY,
        apiKey: this.copyStakeIntegration.apiKey || null,
        ttl: this.copyStakeIntegration.ttl || null,
        callbackUrl: this.copyStakeIntegration.callbackUrl || null,
        brandName: this.copyStakeIntegration.brandName
      };
    },
    integrationMode(): ECopyStakeIntegrationMode {
      return (
        this.copyStakeIntegration.integrationMode ||
        ECopyStakeIntegrationMode.GENERAL
      );
    },

    canUpdate(): boolean {
      return this.$role.can.update('copy_stake streamers');
    },
    isLoadingIntegrationMode(): boolean {
      return (
        this.loading.copyStakeIntegration || this.isSubmittingIntegrationMode
      );
    },
    isReadonly(): boolean {
      return (
        !this.canUpdate ||
        this.loading.copyStakeIntegration ||
        this.isSubmitting ||
        this.isSubmittingIntegrationMode ||
        this.isLoadingIntegrationMode
      );
    },
    isUpdated(): boolean {
      return !isEqual(this.savedValues, this.values);
    },
    submitDisabled(): boolean {
      return (
        !this.isUpdated ||
        !this.formValid ||
        this.loading.copyStakeIntegration
      );
    },

    validationRules(): {
      apiKey: TValidator[];
      ttl: TValidator[];
      callbackUrl: TValidator[];
      brandName: TValidator[];
    } {
      return {
        apiKey: [(v) => required(v, 'API Key')],
        ttl: [
          (v) => required(v, 'TTL Session'),
          integer,
          validNumber,
          positiveNumber
        ],
        callbackUrl: [(v) => required(v, 'Callback URL'), vr.url],
        brandName: [(v) => required(v, 'App Name')]
      };
    },
    fields(): TFormField[] {
      return [
        {
          label: 'API Key',
          tooltip: 'API Key for integration',
          key: 'apiKey',
          classes: { 'no-pointer-events': !this.canUpdate }
        },
        {
          label: 'TTL Session',
          tooltip: 'TTL for session synchronization in a minutes',
          key: 'ttl',
          classes: { 'no-pointer-events': !this.canUpdate }
        },
        {
          label: 'Callback URL',
          tooltip: 'Callback URL for integration',
          key: 'callbackUrl',
          classes: { 'no-pointer-events': !this.canUpdate }
        }
      ];
    },
    nocodeIntegrationCode(): string {
      return generateNocodeIntegrationCode(
        this.copyStakeIntegration.integrationId
      );
    }
  },
  mounted(): void {
    this.getSavedSettings();
  },
  methods: {
    ...mapActions('Onboarding', ['getCopyStakeIntegrationData']),
    updateSettings(): void {
      if (this.isSubmitting) return;

      this.isSubmitting = true;

      updateCopystakeIntegration(this.values)
        .then(() => {
          this.getSavedSettings();

          this.$toast.success('CopyStake settings updated.');
        })
        .catch(() => this.$toast.error('CopyStake settings update failed.'))
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    getSavedSettings(): void {
      this.getCopyStakeIntegrationData()
        .then(() => {
          this.values.copyMode =
            this.copyStakeIntegration.copyMode || ECopyStakeCopyMode.ON_FLY;
          this.values.apiKey = this.copyStakeIntegration.apiKey || null;
          this.values.ttl = this.copyStakeIntegration.ttl || null;
          this.values.callbackUrl =
            this.copyStakeIntegration.callbackUrl || null;
          this.values.brandName =
            this.copyStakeIntegration.brandName || 'Copystake';
        })
        .catch((err: AxiosError) => {
          errorToastMessage(err);
        });
    },
    handlerChangeCopyStakeIntegrationTypeSwitch(
      integrationMode: ECopyStakeIntegrationMode
    ): void {
      this.isSubmittingIntegrationMode = true;

      updateCopystakeIntegrationMode(integrationMode)
        .then(() => {
          this.getSavedSettings();
        })
        .catch((err: AxiosError) => {
          errorToastMessage(err);
        })
        .finally(() => {
          this.isSubmittingIntegrationMode = false;
        });
    }
  }
};
