




















import Prism from 'vue-prismjs';

import 'prismjs';
import 'prismjs/themes/prism-coy.css';
import 'prismjs/plugins/line-numbers/prism-line-numbers';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
import 'prismjs/plugins/normalize-whitespace/prism-normalize-whitespace.min';

import { copyToClipBoard } from '@/helpers/copyToClipBoard';

export default {
  name: 'CodeHighlightedBlock',
  components: {
    Prism
  },
  props: {
    code: {
      type: String,
      required: true
    },
    language: {
      type: String,
      default: 'javascript'
    }
  },
  data(): any {
    return {
      isMounted: false
    };
  },
  mounted(): void {
    this.isMounted = true;
  },
  methods: {
    copyToClipBoard
  }
};
